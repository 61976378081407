<script>
export default {
  name: 'page-detail',
  data() {
    return {
      page: null
    }
  },
  watch: { 
    $route(to, from){ 
      // react to route changes... 
      if(to !== from){ location.reload(); } 
    } 
  },
  mounted() {
    this.getDetailPage()
  },
  methods: {
    getDetailPage() {
      this.axios.get(`page/slug/${this.$route.params.slug}`)
        .then( response => {
          this.page = response.data.result.page
        })
        .catch( () => {
          this.$router.push('/404')
        })
    },
  }
}
</script>

<template>
  <div v-if="page">
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>{{ page.title }}</span>
        <p class="text-center">
          {{ page.subtitle }}
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub_news mt-4">
        <div class="row">
          <div class="col-md-12">
            <div class="content mb-40">
              <div class="_content-news no-border has-detail">
                <img v-if="page.banner" :src="page.banner" alt="">
                <div class="_sub">
                  <span class="d-block font-14px font-weight-6 mb-5">{{ page.title }}</span>
                  <p class="mb-19 ln-2  bc-grey  border-bot-1 pb-4 font-14 color-grey-dark" v-html="page.content"></p>
                </div>
              </div>    
            </div>      
          </div>
        </div>
      </div>
    </div>
  </div>
</template>